export default class FavoritePost {
  constructor(config = {}) {
    this.el = config.el || document.querySelector(".favorite-post");
    this.favoriteItem = this.el;
    this.likeCount = this.el.querySelector(".favorite-post__count");
    this.likeCountVal = this.el.querySelector(
      ".favorite-post__count"
    ).innerHTML;
    this.favClass = "post--liked";
    this.init();
  }

  init() {
    this.favoriteThis();
    this.checkLikedPost();
    this.closeCaptcha();
  }

  // check to see if post was previously liked and add class
  checkLikedPost() {
    const favItems = document.querySelectorAll(".favorite-post");

    // get array of saved liked posts
    var existingEntries = JSON.parse(localStorage.getItem("likedEntries"));
    if (existingEntries == null) existingEntries = [];

    // loop through array and get id to compare and add class
    existingEntries.forEach((dataId) => {
      var postid = "[data-postid=" + dataId + "]";
      var likedId = document.querySelector(postid);

      if (likedId !== null) {
        likedId.classList.add(this.favClass);
      }
    });
  }

  favoriteThis() {
    const self = this;
    self.favoriteItem.addEventListener("click", function (e) {
      var postId = this.dataset.postid;

      e.preventDefault();

      var url =
        location.protocol +
        "//" +
        location.hostname +
        "/api/sitecore/botdetectvalidation/ValidateSession";

      $.post(url, function (result) {
        if (result.success === false) {
          $("body").addClass("captcha-open");
          $("#captchaModal").show();
          $("#captchaModal").find($(".captcha-modal__input").focus());
          $(
            "#content :focusable, .content-action-links :focusable, .footer-container :focusable, .skip"
          )
            .not(".captcha-modal__content :focusable")
            .addClass("unfocus")
            .attr("tabindex", -1)
            .attr("disabled", true);
          $('#captchaModal').attr("captchaPostId", postId);
        } else {
          if (self.el.classList.contains(self.favClass)) {
            self.el.classList.remove(self.favClass);
            self.removeCounter();
            self.submitLike(postId, false);
          } else {
            self.el.classList.add(self.favClass);
            self.addCounter();
            self.submitLike(postId, true);
          }
        }
      });
    });
  }

  submitLike(postId, favorite) {
    $.post(
      "/api/sitecore/favorite/SaveLike?postId=" + postId + "&like=" + favorite
    );

    // get array of saved liked posts
    var existingEntries = JSON.parse(localStorage.getItem("likedEntries"));
    if (existingEntries == null) existingEntries = [];

    // if id already in array, remove from array
    if (existingEntries.includes(postId)) {
      let index = existingEntries.indexOf(postId);
      existingEntries.splice(index, 1);
      localStorage.setItem("likedEntries", JSON.stringify(existingEntries));
    } else {
      localStorage.setItem("thisLike", JSON.stringify(postId));
      // Save allEntries back to local storage
      existingEntries.push(postId);
      localStorage.setItem("likedEntries", JSON.stringify(existingEntries));
    }
  }

  addCounter() {
    const self = this;
    let newLikeCount = ++self.likeCountVal;
    self.updateDisplay(newLikeCount);
  }

  removeCounter() {
    const self = this;
    var newLikeCount = self.likeCountVal;

    if (newLikeCount == 0) {
      self.updateDisplay("0");
    } else {
      let newLikeCount = --self.likeCountVal;
      self.updateDisplay(newLikeCount);
    }
  }

  updateDisplay(val) {
    const self = this;
    self.likeCount.innerHTML = val;
  }
  
  closeCaptcha() {
    var captchaClose = document.querySelector(".close-captcha");
    if (captchaClose.getAttribute("clickListener") !== "true") {

      captchaClose.setAttribute("clickListener", "true");
      captchaClose.addEventListener("click", function () {

        var errorMsg = document.getElementsByClassName("captcha-error-msg");
        errorMsg[0].style.display = "none";

        document.querySelector("#captchaModal").style.display = "none";
        document.body.classList.remove("captcha-open");
        $('.unfocus').not('.slider__slide-offset').attr('tabindex', 0).removeClass('unfocus').removeAttr('disabled');
      });
    }
  }
}
